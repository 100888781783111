import { useEffect } from "react";

export interface ExtendedWindow extends Window {
  dataLayer?: {
    event: string;
    event_name: string;
    user_status: string | undefined;
    user_id: string | undefined;
    quote_id: string | undefined;
    page_type: string | undefined;
    page_referrer: string | null;
    page_url: string | undefined;
    page_title: string | undefined;
    site_version: string | undefined;
  }[];
}

type PageViewProps = {
  userId: string | undefined;
  quoteId: string | undefined;
  pageReferrer: string | null;
  pageUrl: string | undefined;
  pageTitle: string | undefined;
};

const useGtmPageView = (props: PageViewProps) => {
  const { userId, quoteId, pageReferrer, pageUrl, pageTitle } = props;
  useEffect(() => {
    const triggerPageView = () => {
      const extendedWindow = window as ExtendedWindow;
      extendedWindow.dataLayer = extendedWindow.dataLayer ?? [];
      extendedWindow.dataLayer.push({
        event: "page_load",
        event_name: "page_view",
        user_status: "guest",
        user_id: userId,
        quote_id: quoteId,
        page_type: "quote & buy",
        page_referrer: pageReferrer,
        page_url: pageUrl,
        page_title: pageTitle,
        site_version: "1.5",
      });
    };
    if (pageUrl) triggerPageView();
    return () => {
      // Cleanup code if needed
    };
  }, [userId, quoteId, pageReferrer, pageUrl, pageTitle]);
};

export default useGtmPageView;
