import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@/Redux/root-reducer";
import { updateProgress } from "@/Redux/Slices/progressSlice";

export default function useUpdateProgressBar(percent: number) {
  const dispatch = useDispatch();
  const progress = useSelector((state: RootState) => state.progress);

  useEffect(() => {
    // if the current progress is -1 and new progress is not -1
    // need to load the progress first by setting to 0 and then setting to the new progress
    if (percent !== -1 && progress === -1) {
      dispatch(updateProgress(0));
      setTimeout(() => {
        dispatch(updateProgress(percent));
      }, 500);
    } else {
      dispatch(updateProgress(percent));
    }
    return () => {};
  }, [dispatch, percent, progress]);
}
