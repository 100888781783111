import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: number = -1;

const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    updateProgress: (state, action: PayloadAction<typeof initialState>) => {
      // eslint-disable-next-line no-param-reassign
      state = action.payload;
      return state;
    },
  },
});

export const { reset, updateProgress } = progressSlice.actions;
export default progressSlice.reducer;
